















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ControlCenter extends Vue {
  origin: any = "";
  created() {
    localStorage.setItem('shopId',this.$store.getters.storeId)
    localStorage.setItem('api_type','shop')
    this.origin = location.origin;
  }
}
