















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ControlCenter extends Vue {
    origin: any = "";
    created() {
        this.origin = location.origin;
    }
}
